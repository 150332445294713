@import "../../constants/colors.scss";

.groupIcon{
    cursor:pointer;
    background-color:$blue3;
    width: 55px;
    height: 55px;
    min-height: 55px;
    border-radius:15px;
    display:flex;
    align-items:center;
    justify-content:center;
    span{
        font-size: 20px;
        font-weight:bold;
    }
}