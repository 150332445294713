@import "../constants/colors.scss";

@media(min-width:991px){
    .bottombar{
        display: none !important;
    }
  }
  

 .bottombar{
        z-index:5;
        box-shadow:0px 0px 2px rgba(0, 0, 0, 0.2);
        height:75px;
        width:100%;
        overflow:hidden;
        position:fixed;
        bottom:0;
        background-color:white;
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
     div{
         width:100%;
         height:100%;
         display:flex;
         justify-content:center;
         align-items:center;
     }
}

.bottomIconCont{
    flex:1;
    height: 60px;
    width:60px;
    display:flex;
    justify-content:center;
    align-items:center;
}

.bottomActive{
    border-radius:15px;
    background-color:$blue4;
    i,svg{
        color:$blue1;
    }
}
