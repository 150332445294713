@import "../../constants/colors.scss";

.react-contexify {
  position: fixed;
  opacity: 0;
  user-select: none;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 8px 14px -5px rgba(80, 84, 85, 0.23);
  border-radius: 8px;
  min-width: 175px;
  z-index: 100;
  overflow: hidden;

  &__submenu--is-open,
  &__submenu--is-open > &__item__content {
    color: white;
    background-color:#00e8d1;
  }

  &__submenu--is-open > &__submenu {
    pointer-events: initial;
    opacity: 1;
  }

  & &__submenu {
    position: absolute;
    /* negate padding */
    top: -6px;
    pointer-events: none;
    transition: opacity 0.275s;
  }

  &__submenu-arrow {
    margin-left: auto;
    font-size: 12px;
  }

  &__separator {
    width: 200%;
    height: 1px;
    cursor: default;
    margin: 0px -10%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__will-leave--disabled {
    pointer-events: none;
  }

  &__item {
    cursor: pointer;
    position: relative;
    &:focus{
      outline: 0;
    }

    &:not(&--disabled):hover > &__content,
    &:not(&--disabled):focus > &__content {
      background-color: #00e8d1;
    }

    &:not(&--disabled):hover > .react-contexify__submenu {
      pointer-events: initial;
      opacity: 1;
    }

    &--disabled {
      cursor: default;
      opacity: 0.5;
    }
    &__content {
      padding: 10px 15px;
      color: $blue1;
      font-weight: bold;
      width: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: space-between;
      position: relative;
     
    }
  }
}
