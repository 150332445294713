@import "../../constants/colors.scss";

.formCont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  p{
    text-align: start;
  }
  .section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .rowSect {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .miniAdd {
    //padding: 15px;
    height: 40px;
    width: 60px;
    margin: auto;
    border-radius: 15px;
    background-color: $btnBlueBg;
  }
  .submit {
    display: block;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
    color: $blue1;
    background-color: $btnBlueBg;
    font-size: 20px;
    font-weight: bold;
    border-radius: 15px;
  }
}
.datesCont{
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  div {
      display: flex;
      flex-direction: column;
  }
}
.showCont{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.noFiles{
  font-size: 16px;
  color: $greyletter;
  text-align: center !important;
  width: 100%;
}
// .formCont::-webkit-scrollbar {
//   width: 11px;
// }
// .formCont::-webkit-scrollbar-track {
//   height: 20%;
//   max-height: 20%;
//   border-radius: 6px;
//   background: $btnBlueBg;
// }
// .formCont::-webkit-scrollbar-thumb {
//   background-color: #abd1f1;
//   border-radius: 6px;
// }

.inputLabel {
  width: 100%;
  color: #131433;
  font-size: 1.1rem;
  font-weight: 700;
  margin-right: 20px;
  text-align: start;
  margin-bottom: 3px;
}
.color{
  width: 100%;
  height: 100%;
  background-color: red;
}

.upload-btn{
  cursor: pointer;
  display: flex;
  width: 100px;
  height: 40px;
  border-radius: 8px;
  color:$blue1;
  background-color: #abd1f1;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  p {
    margin: 0;
    font-weight: bold;
  }
}

.btnscontform{
  display: flex;
  justify-content: space-between;
  width: 100%;
  .submit{
      max-width: 48%;
      .lds-ellipsis{
          margin-left: 32%;
      }
  }
}
.fullwidthbtn{
  width: 100%;
  max-width: 100% !important;
}
@media(max-width:992px){
  .formCont .section{
     width: 100%;
  }
  .formCont .submit{
     width: 100%;
  }
}
