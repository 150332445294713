@import "./constants/colors.scss";

p, span{
    margin:0;
}
$navHeight:78px;
$elemsBigCalendar:33%;
$elemsLittleCalendar: 350px;

body, html{
    height:100%;
    background-color:  #e4f0f1;
}
body.whitebg{
    background-color:  white;
}
body.nooverflow{
    height:100vh;
    overflow-y:hidden;
}

.platcontainer{
    width:100%;
    min-height:100vh;
    display:flex;
    flex-direction:row;
}
.navbar{
    height:100vh;
    background-color:$blue1;
    min-width:80px;
}
.maincont{
    width:100%;
    height:100vh;
    display:flex;
    flex-direction:column;
}
.topbar{
    height:$navHeight;
    min-height: $navHeight;
}
.elems-cont{
    height: calc(100vh - #{$navHeight});
    display:flex;
    justify-content:space-between;
    flex-direction:row;
    padding:0 4%;
}
.contentcont{
    height:calc(100vh - #{$navHeight});
    width:calc(100% - #{$elemsBigCalendar});
}
.calendarcont{
    max-width:600px;
    width:$elemsBigCalendar;
    height:calc(100vh - #{$navHeight});
    display:flex;
    justify-content:flex-end;
}

@media(min-width:992px) and (max-width:1200px){
    .contentcont{
    width:calc(100% - #{$elemsLittleCalendar});
}
    .calendarcont{
    width:$elemsLittleCalendar;
    }
}
@media(max-width:992px){
    .navbar{
        display:none;
    }
      .contentcont{
        width:100%;
    }
     .calendarcont{
        display:none;
    }
}
@media(max-width:1300px) and(min-width:576px){
    .elems-cont{
        padding:0 4%;
    }
}
@media(max-width:576px){
    .elems-cont{
        padding:0;
    }
}