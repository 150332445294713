@import "../../constants/colors.scss";
@import "../../layout.scss";

.groupsmobilemodal{
        opacity:1;
        height:calc(100vh - 75px);
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index:50;
        background-color: $blue1;
        .overlay-content {
            height: 100%;
            overflow: hidden;
        }
}
.modalgrouptitle{
    text-align: start;
    width:100%;
    color:white;
    font-size:30px;
    font-weight:bold;
    height:40px;
}
.groupsModalCont{
    width:100%;
    height:calc(100vh - 75px - #{$navHeight} - 70px );
    overflow-y:hidden;
    display:flex;
    flex-direction:column;
    padding: 0 20px;
}
.groupsmodalcontainer{
    display:flex;
    height:calc(100vh - 75px - #{$navHeight} - 70px - 40px);
    flex-direction:column;
    overflow-y:scroll;
}
.groupsmodalcontainer::-webkit-scrollbar {
  width: 8px;
}
.groupsmodalcontainer{
  scrollbar-width: thin;
}

.groupsmodalcontainer::-webkit-scrollbar-track {
  height:20%;
  max-height:20%;
  border-radius:4px;
  background: transparent;
}
.groupsmodalcontainer::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
  border: 2px solid transparent;
}

.groupIconModalCont{
    width:100%;
    display:flex;
    align-items:center;
    margin-bottom:20px;
}
.groupNameModal{
    font-size:23px;
    color:white;
    font-weight:bold;
    margin-left:20px;
    text-align:start;
    width:90%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

@media(min-width:992px){
    .groupsmobilemodal{
        display:none !important;
    }
}