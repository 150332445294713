.customModal {
  //aqui per alguna raó pilla un estilitzat q no se si ya ve donat dalun lloc, l'aprofitem
  display: flex;
  flex: 1;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-self: center;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding: 8vh 0;
}

.modal-container {
  max-width: 600px;
  position: relative;
  background-color: white;
  margin: auto;
  width: 35vw;
  min-width: 500px;
  border-radius: 20px;
  padding: 50px;
  -webkit-box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.7);
}
.modalX {
  position: absolute;
  cursor: pointer;
  width: 40px;
  height: 40px;
  top: 20px;
  right: 20px;
  // box-shadow: 0px 3px 5px rgb(0 0 0 / 33%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  img {
    width: 40%;
  }
}

@media (max-width: 575px) {
  .modal {
    padding: 20px;
  }
  .modal-container {
    width: 80vw;
    min-width: 100px;
  }
}

.titleContainer {
  display: flex;
  align-items: flex-start;
  .title {
    font-weight: 700;
    color: #131433;
    font-size: 28px !important;
    text-align: start;
  }
}



@media(max-width:992px){
   .modal-container{
      padding-right: 35px;
      padding: 35px;
      width: calc(100% - 40px);
   }
}