@import "../layout.scss";

.spinner-cont{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    img {
        max-height: 150px;
    }
}

@media(max-width:992px){
    .spinner-cont{
        height: calc(100vh - #{$navHeight} - 80px);
    }
}