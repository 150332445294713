@import "../constants/colors.scss";

@media (max-width: 992px) {
  .nav-bar {
    display: none !important;
  }
}

.nav-bar {
  display: flex;
  height: 100vh;
  width: 80px;
  min-width: 80px;
  background: #131433;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
}

.logo {
  order: 1;
  margin: auto;
  margin-top: 16px;
  width: 43px;
  height: 43px;
  align-self: flex-start;
}

.nav-menu-items {
  display: flex;
  flex-direction: column;
  margin-block-start: 45px;
  flex: 1;
  order: 2;
  list-style: none;
  padding: 0;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
}

.item-container {
  display: flex;
  height: 55px;
  width: 55px;
}
.navItemCont{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.navItemContGroup{
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 7.5px;

}
.activeBar{
  position: absolute;
  height: 100%;
  width:4px;
  border-radius: 4px;
  right: 0;
  background-color: #00e8d1;
}
.icon {
  flex: 1;
  height: 55px;
  width: 55px;
}

.active-icon {
  height: 55px;
  width: 55px;
  border-radius: 15px;
  background-color: #00e8d1;
}

.group {
  width: 55px;
  height: 55px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  color: grey;
  text-decoration: none;
}

.active-group {
  margin: auto;
  width: 55px;
  height: 55px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  color: grey;
  text-decoration: none;
  border: 3px solid #00e8d1;
}

.group:hover {
  text-decoration: none;
  color: grey;
}

.active-group:hover {
  text-decoration: none;
  color: grey;
}

.add {
  order: 3;
  margin: auto;
  width: 55px;
  height: 55px;
  border-style: solid;
  border-radius: 15px;
  border-width: 1px;
  border-color: #c7dcef;
}

.groups {
  order: 4;
  list-style: none;
  padding-bottom: 16px;
  align-self: flex-end;
}

.line {
  order: 5;
  margin: auto;
  width: 54px;
  border-width: 0;
  height: 1px;
  background-color: #c7dcef;
  align-self: flex-end;
}

.user {
  cursor: pointer;
  margin: auto;
  margin-block-end: 16px;
  margin-block-start: 16px;
  order: 6;
  width: 53px;
  height: 53px;
  background: white;
  border-radius: 30px;
  align-self: flex-end;
  align-content: flex-end;
}

//Modal
.navbarPopup {
  bottom: 25px !important;
  left: 90px !important;
}

.userOption {
  width: 100%;
  display: flex;
  justify-content: space-between;

}
