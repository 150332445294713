@import "../../constants/colors.scss";

.megacontainer{
    width:100%;
    height:100vh;
    background: #E4F0F1;
    display:flex;
    justify-content:center;
    align-items:center;
}

.authformcont{
    box-shadow: 0 20px 30px Rgb(93 142 147 / 33%);
    width:90%;
    max-width:600px;
    background-color: white;
    min-height:30%;
    border-radius:20px;
    padding:50px;
}
.authform{
    display:flex;
    flex-direction:column;
}
.auth-image{
    width:40px;
}
.auth-main{
    text-align: start;
    margin-top:15px;
    color: $blue1;
    font-weight:bold;
    font-size:23px;
}
.form-text{
    text-align: start;
}
.text-muted:hover{
    cursor: pointer;
    text-decoration: underline;
}
.forgot-pw{
    cursor:pointer;
    color:$greyletter;
    margin:0;
    &:hover{
        text-decoration:underline;
    }
}
.authbtn{
    cursor:pointer;
    width:100%;
    height:55px;
    border-radius: 10px;
    font-weight: bold;
    border:none;
    outline:none;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 1.15rem;
    transition: all 0.5s;
}
.authbtn:hover{
     transform: translateY(-3px);
}
.loginbtn{
    color:$blue3;
    background-color: $blue1;
}

.spinnercont{
    cursor: pointer;
    background-color: $blue1;
    display:flex;
    align-items:center;
    height:55px;
    border-radius: 10px;
}
/*Material UI improvements*/
.MuiInputAdornment-root{
    cursor: pointer;
}
.MuiFormHelperText-contained{
    margin-left: 0 !important;
}
/*End Material UI improvements*/
.noaccount{
    display:flex;
    justify-content: center;
    color:$greyletter;
    p{
        margin:0;
    }
    span{
        margin:0;
        margin-left:3px;
        font-weight:bold;
        cursor:pointer;
        &:hover{
            text-decoration:underline;
        }
    }
    
}

@media(max-width:576px){
    .auth-main{
    font-size:24px;
    }
    .megacontainer{
        min-height:700px;
    background-color:white;
    }
    
.authformcont{
    box-shadow:none;
    padding:0;
    }
}