$blue1: #131433 !default;
$blue2: #2bbff2 !default;
$blue3: #8ce3fa !default;
$blue4: #00e8d1 !default;
$blue5: #8cf0de !default;
$blue6: rgba(42, 80, 160, 0.1) !default;
$blue7: #C1D7EA !default;
$blue8: #EFF2FB !default;
$blue9: #C7DCEF !default;
$green: #78e5a3 !default;
$darkgreen:#0E6454 !default;

$klaseabg: #E4F0F1 !default;
$greyletter: #6A7498 !default;
$primary: $blue1 !default;

$btnBlueBg: $blue9 !default;
$btnGreenBg: #A9EDE1 !default;

$white-bg:#F3F3F5 !default;

a, a:hover{
    color: inherit;
    text-decoration: none;
}