.topbar{
    padding: 0 4%;
    display:flex;
    justify-content:center;
    align-items:center;
}

//Center of the navbar content
.center-col{
    display:flex;
    flex-direction:row;
    align-items:center;
    font-size:22px;
}
#division{
    height:25px;
    border-left: 2px solid;
}
.burguer-icon, .burguer-icon-white{
    width:30px;
}
.burguer-icon-white{
    color:white;
}
@media(min-width:993px){
    .burguer-icon{
        display:none;
    }
}
@media(max-width:992px){
    .center-col{
        display:none;
    }
    .topbar{
        justify-content:flex-end;
    }
}
@media(max-width:600px){
    .topbar{
        padding: 0 20px;
    }
}