.Toastify__toast-container {
    width: 320px;
  }
  .Toastify__toast--default {
      background: #A5F0BF;
      color: #0B5C4E;
   }
   .Toastify__progress-bar--default {
    background: linear-gradient(to right, #A5F0BF, #A5F0EB, #8CE3FA, #8CC5FA, #9BB1FC);
}
    .Toastify__toast--info {
      background: #3498db;
   }
    .Toastify__toast--success {
      background: #07bc0c;
   }
    .Toastify__toast--warning {
      background: #f1c40f;
   }
    .Toastify__toast--error {
      background: #e74c3c;
   }