@import "../constants/colors.scss";

.btnbluearrow{
    background-color: $btnBlueBg;
    color: $blue1;
    padding: 8px 16px;
    border-radius: 10px;
    cursor:pointer;
    display:flex;
    align-items:center;
    span{
        margin-right: 10px;
    }
    i, svg{
        transition: all 0.5s;
    }
    &:hover{
        i, svg{
             transform: translateX(4px);
        }
    }
}
.btnblueplus{
    background-color: $btnBlueBg;
    color: $blue1;
    padding: 6px 12px;
    border-radius: 10px;
    font-weight:bold;
    cursor:pointer;
    display:flex;
    justify-content: space-around;
    align-items:center;
    span{
        margin-right: 10px;
    }
}

.btnbluegreen{
    background-color: $btnGreenBg;
    color: $darkgreen;
    padding: 8px 16px;
    border-radius: 10px;
    font-weight:bold;
    cursor:pointer;
    display:flex;
    align-items:center;
    span{
        margin:0;
        margin-right: 10px;
    }
     i, svg{
        transition: all 0.5s;
    }
    &:hover{
        i, svg{
             transform: translateX(4px);
        }
    }
}
.btngreen{
    min-width: 100px;
    min-height: 40px;
    background-color: $btnGreenBg;
    color: $darkgreen;
    padding: 8px 16px;
    border-radius: 10px;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content: center;
    span{
        margin:0;
        margin-left: 10px;
    }
     i, svg{
        transition: all 0.5s;
    }
    &:hover{
        i, svg{
             transform: translateX(4px);
        }
    }
}

.btndisabled{
    color:grey !important;
    cursor: not-allowed;
}

.sessionBtn{
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -webkit-align-items: center;
    align-items: center;
    font-weight:bold;
    i, svg, img{
        width:17px;
    }
    span{
        font-size: 14px;
    }
}

.recordingBtn{
    border: 1px solid #882c2c;
    color: #882c2c;
    span{
        margin-left:5px;
    }
    &:hover{
        background-color:#efc7c7;
        border-color:#efc7c7;
    }
}
.noRecording {
    cursor: not-allowed!important;
}

.showDocBtn{
    background-color: #c7dcef;
    color: #131433;
    border: 1px solid #6a7498;
    span{
        margin-right:5px;
    }
    &:hover{
        background-color: #131433;
        border-color: #131433;
        color: #fff;
        opacity: .9;
    }
}

.bigbluebutton{
    width:100%;
    display:flex;
    justify-content:center;
    padding:15px 0;
    background-color:$btnBlueBg;
    color:$blue1;
    font-weight:bold;
    border-radius:15px;
    font-size:20px;
    cursor:pointer;
}
.forumbutton{
    cursor:pointer;
    padding: 6px 3px;
    width: 70px;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: $blue8;
    border-radius: 10px;
    color: $greyletter;
    border: 1px solid $greyletter;
    font-weight: bold;
    font-size: 15px;
}
.disabledEventBtn {
    cursor: not-allowed;
    color: grey!important;
    &:hover{
        i,svg {
            transform: none !important;
        }
    }
}

.showbtnblue{
    color: #131433;
    padding: 6px 12px;
    border-radius: 10px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    span{
        font-size: 1rem;
        margin-right: 8px;
    }
}